import React from 'react'
import './CenteredLayout.css'

const CenteredLayout = ({ adjustY = '0px', children }) => (
  <div style={{ marginTop: adjustY }} className="centeredLayout">
    {children}
  </div>
)

export default CenteredLayout
