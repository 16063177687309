import { message, Steps } from "antd";
import CenteredLayout from "components/CenteredLayout";
import Logo from "components/Logo";
import strings from "i18n/strings";
import React from "react";
import CodeForm from "./CodeForm";
import EmailForm from "./EmailForm";

class Auth extends React.Component {
  constructor(props) {
    super(props);
    this.state = { email: null };
  }

  render() {
    return (
      <CenteredLayout adjustY="-40px">
        <div className="login-logo">
          <Logo />
        </div>
        <Steps progressDot size="small" current={!this.state.email ? 0 : 1}>
          <Steps.Step title="Email" />
          <Steps.Step title={strings.passCode} />
          <Steps.Step title={strings.uploadPhotos} />
        </Steps>
        {!this.state.email ? (
          <EmailForm
            onSuccess={(email) => {
              this.setState({ email });
              message.info(strings.passwordIsSent);
            }}
          />
        ) : (
          <CodeForm email={this.state.email} onSuccess={this.props.onSuccess} />
        )}
      </CenteredLayout>
    );
  }
}

export default Auth;
