import { message } from "antd";
import S3 from "aws-sdk/clients/s3";
import * as bb from "bluebird";
import strings from "i18n/strings";
import generate from "nanoid/generate";
import api from "./api";

function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export default async (
  authorId,
  matterId,
  fileList,
  onProgress,
  onFatalError
) => {
  window.onbeforeunload = function () {
    return strings.interruptUpload;
  };
  let uploadCount = 0;
  let uploadedFiles = [];
  try {
    var uploadInstance = await api.createUpload(matterId);
  } catch (error) {
    onFatalError(error);
  }

  let s3Credentials = await api.getS3Credentials(uploadInstance.id);
  let s3 = new S3({
    region: "eu-west-1",
    accessKeyId: s3Credentials.AccessKeyId,
    secretAccessKey: s3Credentials.SecretAccessKey,
    sessionToken: s3Credentials.SessionToken,
  });
  const credentialsUpdateInterval = setInterval(
    async () => {
      let s3Credentials = await api.getS3Credentials(uploadInstance.id);
      s3 = new S3({
        region: "eu-west-1",
        accessKeyId: s3Credentials.AccessKeyId,
        secretAccessKey: s3Credentials.SecretAccessKey,
        sessionToken: s3Credentials.SessionToken,
      });
    },
    1000 * 60 * 55
  ); // 5 minutes before expiration
  return bb.resolve(fileList).map(
    async function uploadFile(file) {
      try {
        await s3
          .upload({
            Bucket: process.env.REACT_APP_S3_BUCKET,
            Key: `batch-upload/${authorId}/${uploadInstance.id}/${generate(
              "1234567890abcdef",
              15
            )}.jpg`,
            Body: file,
          })
          .promise();
      } catch (error) {
        message.warn(
          `${strings.errorWhileUploadingFile} ${file.name}: ${error.message}. ${strings.restartIn}`
        );
        await delay(10000);
        return uploadFile(file);
      }
      uploadCount++;
      uploadedFiles = uploadedFiles.concat([file]);
      onProgress(
        Math.ceil((uploadCount / fileList.length) * 100),
        uploadedFiles
      );
      if (uploadCount === fileList.length) {
        window.onbeforeunload = null;
        clearInterval(credentialsUpdateInterval);
        try {
          await api.finishUpload(uploadInstance.id);
        } catch (error) {
          onFatalError(error);
        }
      }
    },
    { concurrency: 10 }
  );
};
