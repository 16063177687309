import { Result } from "antd";
import strings from "i18n/strings";
import React, { Component } from "react";
import Auth from "./components/Auth";
import CenteredLayout from "./components/CenteredLayout";
import Upload from "./components/Upload";
import api from "./services/api";
import matterId from "./services/matterId";

class Root extends Component {
  constructor(props) {
    super(props);
    this.state = { user: null, loadingUser: true };
  }

  onAuth(user) {
    this.setState({ user });
  }

  componentDidMount() {
    this.setState({ loading: true });
    api.authenticatedUser().then((user) => {
      this.setState({ user, loadingUser: false });
    });
  }

  render() {
    if (!matterId)
      return (
        <CenteredLayout>
          <Result
            status="warning"
            title={strings.incorrectURL}
            subTitle={strings.requestCorrectURL}
          />
        </CenteredLayout>
      );
    if (this.state.loadingUser) return <></>;
    if (!this.state.user) return <Auth onSuccess={this.onAuth.bind(this)} />;

    return <Upload user={this.state.user} />;
  }
}

export default Root;
