import { Descriptions, Popconfirm } from "antd";
import strings from "i18n/strings";
import React from "react";
import matterId from "services/matterId";
import api from "../../services/api";

class UploadInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      photographer: props.user,
      matter: null,
      loading: true,
    };
  }

  async componentDidMount() {
    let response = null;
    this.setState({ loading: true });
    try {
      response = await api.getAlbumInfo(matterId);
      if (!response) throw Error(strings.unauthorized(this.props.user.email));
      if (response.sealed) throw Error(strings.albumIsSealed);
    } catch (error) {
      return this.props.onLoadingError(error);
    }
    this.setState({ matter: response, loading: false });
    this.props.onSuccess();
  }

  render() {
    return this.state.loading ? null : (
      <Descriptions
        bordered={true}
        layout="vertical"
        size="small"
        title={strings.uploadPhotos}
      >
        <Descriptions.Item label={strings.album}>
          {this.state.matter.name}{" "}
          {this.state.matter.caption && `(${this.state.matter.caption})`}
        </Descriptions.Item>
        <Descriptions.Item label={strings.photographer}>
          {this.state.photographer.firstName} {this.state.photographer.lastName}{" "}
          ({this.state.photographer.email}){" "}
          <Popconfirm
            title={strings.wantToSwitchAccount}
            onConfirm={this.props.onLogout}
            okText={strings.yes}
            cancelText={strings.no}
          >
            <a href="/">{strings.switchAccount}</a>
          </Popconfirm>
        </Descriptions.Item>
      </Descriptions>
    );
  }
}

export default UploadInfo;
