import Axios from "axios";
const apiURL = /myrace.info/.test(window.location.hostname)
  ? "https://photo-api.myrace.info/api/graphql"
  : /kadr.app/.test(window.location.hostname)
    ? "https://api.kadr.app/api/graphql"
    : "https://api.portreed.com/api/graphql";
const axios = Axios.create({
  baseURL: apiURL,
  withCredentials: true,
});

export default {
  async getAlbumInfo(albumId) {
    return (
      await axios.post("", {
        query: `{
          album (where: {id: "${albumId}"}) { id name caption sealed }
        }
        `,
      })
    ).data.data.album;
  },

  async authenticatedUser() {
    return (
      await axios.post("", {
        query: `query {
          authenticatedItem { ... on User { id email firstName lastName } }
        }
        `,
      })
    ).data.data.authenticatedItem;
  },

  async endSession() {
    await axios.post("", {
      query: `mutation { endSession }`,
    });
  },

  async sendOTP(email) {
    return await axios.post("", {
      query: `mutation {
        sendUserMagicAuthLink(email: "${email}")
      }`,
    });
  },

  async redeemOTP(email, code) {
    const response = await axios.post("", {
      query: `mutation {
            redeemUserMagicAuthToken(email: "${email}", token: "${code}") {
              ... on RedeemUserMagicAuthTokenSuccess {
                item { id email firstName lastName }
                token
              }
              ... on RedeemUserMagicAuthTokenFailure {
                code
                message
              }
            }
          }`,
    });
    if (response.data.data.redeemUserMagicAuthToken.code) throw new Error();
    return response.data.data.redeemUserMagicAuthToken.item;
  },

  async createUpload(matterId) {
    return (
      await axios.post("", {
        query: `mutation {
          startUpload(albumId: "${matterId}") { id }
        }
        `,
      })
    ).data.data.startUpload;
  },

  async getS3Credentials(uploadId) {
    return (
      await axios.post("", {
        query: `mutation {
          getUploadCredentials(uploadId: "${uploadId}")
        }
        `,
      })
    ).data.data.getUploadCredentials;
  },

  async finishUpload(uploadId) {
    return (
      await axios.post("", {
        query: `mutation {
          startUploadProcessing(uploadId: "${uploadId}") { id }
        }
        `,
      })
    ).data.data.startUploadProcessing;
  },
};
