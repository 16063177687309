let matterId = null;

const query = window.location.search.substring(1);
const vars = query.split("&");
for (let i = 0; i < vars.length; i++) {
  const pair = vars[i].split("=");
  if (decodeURIComponent(pair[0]) === "matterId") {
    matterId = decodeURIComponent(pair[1]);
  }
}

export default matterId;
