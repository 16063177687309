import { Button, Form, Icon, Input } from "antd";
import strings from "i18n/strings";
import React from "react";
import api from "../../services/api";
import "./Form.css";

class EmailForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: false };
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.form.validateFields(async (err, { email }) => {
      if (!err) {
        this.setState({ isLoading: true });
        try {
          await api.sendOTP(email);
          this.props.onSuccess(email);
        } catch (error) {
          let errorMessage = null;
          switch (error.request.status) {
            case 404:
              errorMessage = strings.noSuchPhotographer;
              break;
            case 400:
              errorMessage = strings.badEmail;
              break;
            default:
              errorMessage = strings.errorOccured;
          }
          this.props.form.setFields({
            email: {
              errors: [new Error(errorMessage)],
            },
          });
        }
      }
      this.setState({ isLoading: false });
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit.bind(this)} className="login-form">
        <Form.Item>
          {getFieldDecorator("email", {
            rules: [
              {
                required: true,
                pattern: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
                message: strings.enterEmail,
              },
            ],
          })(
            <Input
              prefix={<Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />}
              size="large"
              placeholder={strings.enterEmail}
            />
          )}
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            block
            htmlType="submit"
            className="login-button"
            size="large"
            loading={this.state.isLoading}
          >
            {strings.sendPassword}
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create({ name: "email-form" })(EmailForm);
