import React from "react";

const defaultLocale = "en";
const browserLocale = navigator.language.replace(/-\w+/, "");

const strings = {
  ru: {
    passCode: "Код-пароль",
    uploadPhotos: "Загрузка фото",
    noSuchPhotographer: "Нет фотографа с таким email",
    badEmail: "Неверно введён email",
    enterEmail: "Введите email",
    sendPassword: "Выслать код",
    errorOccured: "Произошла ошибка",
    badPassword: "Неверный пароль",
    passwordIsSent: "Код выслан на указанный email",
    enterPassword: "Введите код-пароль из письма",
    logIn: "Войти",
    unauthorized: (email) =>
      `Альбом не существует или у фотографа ${email} нет прав для загрузки фото`,
    albumIsSealed: "Альбом в данный момент не принимает загрузки",
    album: "Альбом",
    photographer: "Фотограф",
    switchAccount: "Сменить аккаунт",
    wantToSwitchAccount: "Войти под другим аккаунтом?",
    yes: "Да",
    no: "Нет",
    loadingAlbum: "Загрузка данных об альбоме",
    scanningFiles: "Поиск JPEG файлов",
    filesAreTooBig: "Файлы превышают макимальный размер",
    noJPEGsFound: "JPEG файлы не найдены",
    filesAreTooBigWithCount: (tooLargeJPEGsCount) =>
      `${tooLargeJPEGsCount} фото имеют размер более 5MB и не будут загружены`,
    startUpload: "Начать загрузку",
    changeDir: "Изменить директорию",
    uploadFinished: "Загрузка завершена.",
    makeAnotherUpload: "Выполнить еще загрузку.",
    uploading: "Идёт загрузка...",
    chooseDirWithPhotos:
      "Выберите папку, которая содержит необходимые фотографии",
    imageRequirements:
      "Снимки должны иметь формат JPEG, а их размер не должен превышать 5MB. Файлы неудовлетворяющие условиям не&nbsp;будут загружены.",
    chooseDir: "Выбрать папку",
    dirRequirements:
      "Директория может иметь поддиректории, в этом случае файлы из поддиректорий также будут считаны. Вы можете загружать файлы напрямую с карты памяти указав, например, папку DCIM карты памяти.",
    uploadSummary: (filesCount, bytes) => (
      <>
        Загрузить <b>{filesCount}</b> фото общим размером{" "}
        <b>{(bytes / 1024 / 1024 / 1024).toPrecision(2)}GB</b>.
      </>
    ),
    incorrectURL: "Некорректная ссылка",
    requestCorrectURL: "Запросите у организатора фотосъемки корректную ссылку",
    interruptUpload:
      "Идёт загрузка фотографий на сервер, вы уверены что хотите прекратить?",
    errorWhileUploadingFile: "Ошибка при загрузке файла:",
    restartIn: "Повтор попытки через 10 секунд.",
  },
  en: {
    passCode: "One-time password",
    uploadPhotos: "Photo upload",
    noSuchPhotographer: "There is no photographer with that email",
    badEmail: "Email is invalid",
    enterEmail: "Enter email",
    sendPassword: "Get one-time password",
    passwordIsSent: "One-time password is sent to your email",
    errorOccured: "Error occured",
    badPassword: "Wrong password",
    enterPassword: "Enter password from email",
    logIn: "Log in",
    unauthorized: (email) =>
      `Album does not exist or photographer with email ${email} have no permission to upload to it`,
    albumIsSealed: "Album currently does not accept new uploads",
    album: "Album",
    photographer: "Photographer",
    switchAccount: "Switch account",
    wantToSwitchAccount: "Want to log in with different account?",
    yes: "Yes",
    no: "No",
    loadingAlbum: "Fetchin album info",
    scanningFiles: "Scanning for JPEGs",
    filesAreTooBig: "File are too heavy",
    noJPEGsFound: "No JPEGs found",
    filesAreTooBigWithCount: (tooLargeJPEGsCount) =>
      `${tooLargeJPEGsCount} photos are larger than 5MB and will not be uploaded`,
    startUpload: "Start upload",
    changeDir: "Change directory",
    uploadFinished: "Upload finished.",
    makeAnotherUpload: "Make another upload.",
    uploading: "Uploading...",
    chooseDirWithPhotos: "Choose the directory that contains photos to upload",
    imageRequirements:
      "Photos must be in JPEG format and under 5MB. Photos that do not satisfy won't be uploaded",
    chooseDir: "Choose directory",
    dirRequirements:
      "Directory can has subdirectories, in that case files from subfolders will be uploaded as well. You can upload directly from your memory card if you need to",
    uploadSummary: (filesCount, bytes) => (
      <>
        Upload <b>{filesCount}</b> files with a total size of{" "}
        <b>{(bytes / 1024 / 1024 / 1024).toPrecision(2)}GB</b>.
      </>
    ),
    incorrectURL: "Bad album link",
    requestCorrectURL: "Ask organizer for the correct one",
    interruptUpload: "Upload is in progress, are you sure you want to stop?",
    errorWhileUploadingFile: "Error while uploading:",
    restartIn: "Retrying in 10 seconds.",
  },
};

const requestedLocale = browserLocale;

export default strings[
  strings[requestedLocale] ? requestedLocale : defaultLocale
];
