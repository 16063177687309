import {
  Button,
  Card,
  Icon,
  Progress,
  Result,
  Spin,
  Typography,
  message,
} from "antd";
import CenteredLayout from "components/CenteredLayout";
import strings from "i18n/strings";
import React from "react";
import VirtualList from "react-tiny-virtual-list";
import matterId from "services/matterId";
import upload from "services/upload";
import api from "../../services/api";
import UploadInfo from "./UploadInfo";
const { Paragraph } = Typography;

class Upload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isUploading: false,
      error: null,
      loading: strings.loadingAlbum,
      uploadedFiles: [],
    };
  }

  handleUploadClick() {
    this.refs.input.click();
  }

  async upload() {
    this.setState({ isUploading: true });

    await upload(
      this.props.user.id,
      matterId,
      this.state.filesToUpload,
      (progress, uploadedFiles) => {
        this.setState({ uploadProgress: progress - 1, uploadedFiles });
      },
      (error) => this.setState({ error })
    );

    this.setState({ finishedUpload: true, uploadProgress: 100 });
  }

  setFileList() {
    const filesToUpload = [];
    this.setState({ loading: strings.scanningFiles });
    let tooLargeJPEGsCount = 0;
    let totalSize = 0;
    /* eslint-disable no-unused-vars */
    for (let file of this.refs.input.files) {
      if (file.type === "image/jpeg") {
        if (file.size < 1024 * 1024 * 5) {
          totalSize += file.size;
          filesToUpload.push(file);
        } else {
          tooLargeJPEGsCount += 1;
        }
      }
    }
    /* eslint-enable no-unused-vars */
    if (filesToUpload.length === 0) {
      this.setState({ loading: false });
      return tooLargeJPEGsCount
        ? message.error(strings.filesAreTooBig)
        : message.error(strings.noJPEGsFound);
    }

    if (tooLargeJPEGsCount)
      message.warning(strings.filesAreTooBigWithCount(tooLargeJPEGsCount));

    this.setState({ filesToUpload, totalSize, loading: false });
  }

  renderSpinner() {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Spin />{" "}
        <span style={{ display: "inline-block", width: "12px" }}> </span>
        {this.state.loading}
      </div>
    );
  }

  renderPreuploadStatistics() {
    return (
      <React.Fragment>
        <Card size="small">
          <VirtualList
            width="100%"
            height={200}
            itemCount={this.state.filesToUpload.length}
            itemSize={24}
            renderItem={({ index, style }) => (
              <pre key={index} style={style}>
                {~this.state.uploadedFiles.indexOf(
                  this.state.filesToUpload[index]
                ) ? (
                  <Icon
                    type="check-circle"
                    theme="twoTone"
                    twoToneColor="#52c41a"
                  />
                ) : (
                  <Icon type="file-image" />
                )}{" "}
                {index + 1}.{" "}
                {this.state.filesToUpload[index].webkitRelativePath} [
                {(
                  this.state.filesToUpload[index].size /
                  1024 /
                  1024
                ).toPrecision(2)}
                MB]
              </pre>
            )}
          />
        </Card>
        <br />
        {!this.state.isUploading ? (
          <React.Fragment>
            <Paragraph>
              {strings.uploadSummary(
                this.state.filesToUpload.length,
                this.state.totalSize
              )}
            </Paragraph>
            <Button type="primary" onClick={() => this.upload()}>
              <Icon type="upload" /> {strings.startUpload}
            </Button>{" "}
            <Button onClick={() => this.handleUploadClick()}>
              <Icon type="folder-open" /> {strings.changeDir}
            </Button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Typography>
              {this.state.finishedUpload ? (
                <React.Fragment>
                  {strings.uploadFinished}{" "}
                  <a href={window.location.href}>{strings.makeAnotherUpload}</a>
                </React.Fragment>
              ) : (
                strings.uploading
              )}
            </Typography>
            <Progress percent={this.state.uploadProgress} />
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  renderDirectoryChoser() {
    return (
      <React.Fragment>
        <Paragraph>{strings.chooseDirWithPhotos}</Paragraph>
        <Paragraph>
          <Icon theme="twoTone" twoToneColor="#eb2f96" type="warning" />{" "}
          {strings.imageRequirements}
        </Paragraph>
        <Button type="primary" onClick={() => this.handleUploadClick()}>
          <Icon type="folder-open" />
          {strings.chooseDir}
        </Button>
        <Paragraph type="secondary">
          <br />
          {strings.dirRequirements}
        </Paragraph>
      </React.Fragment>
    );
  }

  render() {
    if (this.state.error)
      return (
        <CenteredLayout>
          <Result
            status="error"
            title={`${this.state.error.message}`}
            subTitle={JSON.stringify(this.state.error)}
          />
        </CenteredLayout>
      );

    return (
      <CenteredLayout>
        <div style={{ width: "900px" }}>
          <UploadInfo
            onLoadingError={(error) => this.setState({ error })}
            onSuccess={() => this.setState({ loading: false })}
            onLogout={async () => {
              await api.endSession();
              window.location.href = "" + window.location.href;
            }}
            user={this.props.user}
          />
          <br />
          {this.state.loading ? (
            this.renderSpinner()
          ) : (
            <Card>
              {this.state.filesToUpload
                ? this.renderPreuploadStatistics()
                : this.renderDirectoryChoser()}
            </Card>
          )}
          <input
            hidden={true}
            name="dir"
            id="dir_input"
            type="file"
            ref="input"
            webkitdirectory="true"
            directory="true"
            multiple="multiple"
            onChange={() => this.setFileList()}
          />
        </div>
      </CenteredLayout>
    );
  }
}

export default Upload;
