import { Button, Form, Icon, Input } from "antd";
import strings from "i18n/strings";
import React from "react";
import api from "../../services/api";
import "./Form.css";

class CodeForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: false };
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.form.validateFields(async (err, { code }) => {
      if (!err) {
        this.setState({ isLoading: true });
        try {
          const response = await api.redeemOTP(this.props.email, code);
          this.props.onSuccess(response);
        } catch (error) {
          this.props.form.setFields({
            code: {
              errors: [new Error(strings.badPassword)],
            },
          });
        }
      }
      this.setState({ isLoading: false });
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit.bind(this)} className="login-form">
        <Form.Item>
          {getFieldDecorator("code", {
            rules: [
              {
                required: true,
                pattern: /^.+$/,
                message: strings.enterPassword,
              },
            ],
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
              size="large"
              placeholder={strings.enterPassword}
            />
          )}
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-button"
            size="large"
            block
            loading={this.state.isLoading}
          >
            {strings.logIn}
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create({ name: "code-form" })(CodeForm);
